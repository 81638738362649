import { graphql, useStaticQuery } from 'gatsby';

import { AllDirectusPageTitle, PageTitleEdges } from './page-title.interface';

export default (): PageTitleEdges[] => {
  const { allDirectusPageTitle } = useStaticQuery<AllDirectusPageTitle>(graphql`
    query {
      allDirectusPageTitle {
        edges {
          node {
            page_title
            buy_link
            slug
            price {
              value
            }
            buttons
          }
        }
      }
    }
  `);

  return allDirectusPageTitle.edges;
};
